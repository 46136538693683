import React from "react";

import { Col, InputGroup, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";

const OrderItem = (props) => {
  const inputValue =
    new Date(props.departureTime).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }) +
    " - " +
    new Date(props.arrivalTime).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

  const handleChange = () => {
    if (!props.disabled) {
      props.checkHandler(props.index);
    }
  };

  return (
    <Form>
      <Row>
        <Col className="gx-3 col-12">
          <InputGroup className="mb-3 justify-content-center">
            <InputGroup.Checkbox
              checked={props.checked}
              onChange={handleChange}
              aria-label="Checkbox for following text input"
              disabled={props.disabled} // Apply the disabled state to the checkbox
            />
            <Form.Control
              type="text"
              value={inputValue}
              readOnly
              className="text-center"
              disabled={props.disabled} // Apply the disabled state to the input
            />
            <InputGroup.Text className="text-center">
              <span style={{ width: "calc(8ch + 1rem)", opacity: props.disabled ? 0.5 : 1 }}>
                {props.price} {props.currency}
              </span>
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default OrderItem;
