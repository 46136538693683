import React, { useContext, useState } from "react";
import SopotakContext from "../../../store/sopotak-context";
import "bootstrap/dist/css/bootstrap.min.css";
import config from "../../../api/api-helper";
import { Col, Container, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "../../../styles.css"

const OrderSummaryPane = (props) => {
  const {
    invoiceRequested,
    companyName,
    nip,
    city,
    postalCode,
    streetAddress,
    apartmentNumber,
    offersData,
    departureOffer,
    returnOffer,
    firstName,
    lastName,
    email,
    phoneNumber,
    persons,
    children,
    bikes,
    comments,
    recommendation,
    marketingInfoApproved,
    tripType,
    from,
    to,
  } = useContext(SopotakContext);

  const [discountCode, setDiscountCode] = useState("");
  const [discountedBooking, setDiscountedBooking] = useState(null);

  const getInvoiceData = () => {
    if (!invoiceRequested) return null;
    return {
      companyName,
      nip,
      city,
      postalCode,
      streetAddress,
      apartmentNumber,
    };
  };

  const buildBookingBody = (discountCode = "") => {
    const invoiceData = getInvoiceData();
    return {
      departureLocation: from,
      arrivalLocation: to,
      type: tripType,
      departureOffer,
      returnOffer,
      customerInformation: {
        firstName,
        lastName,
        email,
        phoneNumber,
        passengerDetails: {
          numberOfPersons: persons,
          numberOfChildren: children,
          numberOfBikes: bikes,
        },
        invoice: invoiceData,
      },
      additionalInformation: {
        comments,
        recommendation,
        marketingInfoApproved,
        discountCode,
      },
    };
  };

  const handleResponse = async (response) => {
    const data = await response.json();
    if (response.ok) {
      return data;
    } else if (response.status === 409) {
      throw new Error("Duplicate booking detected");
    } else if (response.status === 404 && data.message) {
      throw new Error(data.message);
    } else {
      throw new Error(
        data.message || "An error occurred while processing the request"
      );
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const body = discountedBooking || buildBookingBody(discountCode);

    fetch(`${config.apiUrl}/api/sopotak/v1/bookings`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then(handleResponse)
      .then((data) => {
        console.log("booking response", data);
        window.location.href = data.paymentUrl;
      })
      .catch((error) => {
        console.error("Error:", error.message);
        alert(
          error.message === "Duplicate booking detected"
            ? "Ktoś już zarezerwował kurs w podanej godzinie. Wróć i wyszukaj inny kurs."
            : "Wystąpił nieoczekiwany błąd. Spróbuj ponownie później."
        );
      });
  };

  const submitDiscountCodeHandler = (event) => {
    event.preventDefault();
    if (!discountCode) return;

    const body = buildBookingBody(discountCode);

    fetch(`${config.apiUrl}/api/sopotak/v1/bookings/discounts`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then(handleResponse)
      .then((data) => {
        console.log("booking response", data);
        setDiscountedBooking(data);
      })
      .catch((error) => {
        console.error("Error:", error.message);
        alert(
          error.message === "Discount not found"
            ? "Nie znaleziono kodu zniżkowego. Sprawdź kod i spróbuj ponownie."
            : error.message === "Discount already used"
            ? "Kod zniżkowy został już użyty we wcześniejszej rezerwacji."
            : "Wystąpił nieoczekiwany błąd. Spróbuj ponownie później."
        );
        setDiscountedBooking(null);
      });
  };

  const getTotal = () => {
    const total =
      tripType === "RETURN"
        ? parseFloat(departureOffer.price.amount) +
          parseFloat(returnOffer.price.amount)
        : parseFloat(departureOffer.price.amount);
    return total.toFixed(2);
  };

  const getDiscountedPrice = () => {
    if (!discountedBooking) return getTotal();
    const total =
      tripType === "RETURN"
        ? parseFloat(discountedBooking.departureOffer.price.amount) +
          parseFloat(discountedBooking.returnOffer.price.amount)
        : parseFloat(discountedBooking.departureOffer.price.amount);
    return total.toFixed(2);
  };

  return (
    <div className="form-wrapper">
      <Container fluid>
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <h3>Zamawiający</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <span>
              {firstName} {lastName}
            </span>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <span>email: {email}</span>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <span>telefon: {phoneNumber}</span>
          </Col>
        </Row>
        {invoiceRequested && (
          <>
            <Row className="mt-2">
              <Col lg={{ span: 6, offset: 3 }}>
                <h3>Faktura</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <span>{companyName}</span>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <span>
                  {streetAddress} {apartmentNumber}
                </span>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <span>
                  {postalCode} {city}
                </span>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <span>NIP: {nip}</span>
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-2">
          <Col lg={{ span: 6, offset: 3 }}>
            <h3>Kurs</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <h5>Pasażerowie:</h5>
            <div className="d-flex justify-content-between">
              <span>Liczba osób (powyżej 10 lat):</span>
              <span>{persons}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Liczba dzieci (do 10 lat):</span>
              <span>{children}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Rowery / Cargo:</span>
              <span>{bikes}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <h5>
              Kierunek {from} - {to}:
            </h5>
            <div className="d-flex justify-content-between">
              <span>Data:</span>
              <span>
                {new Date(departureOffer.departureTime).toLocaleDateString()}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Godzina:</span>
              <span>
                {new Date(departureOffer.departureTime).toLocaleTimeString()}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Cena:</span>
              <span>
                {departureOffer.price.amount} {departureOffer.price.currency}
              </span>
            </div>
          </Col>
        </Row>

        {tripType === "RETURN" && (
          <Row>
            <Col xs={12} lg={{ span: 6, offset: 3 }}>
              <h5>
                Kierunek {to} - {from}:
              </h5>
              <div className="d-flex justify-content-between">
                <span>Data:</span>
                <span>
                  {new Date(returnOffer.departureTime).toLocaleDateString()}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Godzina:</span>
                <span>
                  {new Date(returnOffer.departureTime).toLocaleTimeString()}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Cena:</span>
                <span>
                  {returnOffer.price.amount} {returnOffer.price.currency}
                </span>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="mt-2" lg={{ span: 6, offset: 3 }}>
            <h3>Cena</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <div className="d-flex justify-content-between">
              <h5>Razem</h5>
              <span style={{ fontSize: "larger", fontWeight: "bold" }}>
                {getTotal()} {departureOffer.price.currency}
              </span>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg={{ span: 6, offset: 3 }}>Masz kod zniżkowy?</Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }} className="d-flex">
            <Form.Control
              type="text"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              className="me-2"
            />
            <Button  onClick={submitDiscountCodeHandler}>
              Wprowadź
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3" xs={12} lg={{ span: 6, offset: 3 }}>
            <div className="d-flex justify-content-between">
              <h5>Do zapłaty</h5>
              <span style={{ fontSize: "larger", fontWeight: "bold" }}>
                {getDiscountedPrice()} {departureOffer.price.currency}
              </span>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <div className="d-flex">
              <Button
                size="lg"
                onClick={() => props.progressHandler(2)}
                className="w-50 me-2"
              >
                Wstecz
              </Button>
              <Button
                size="lg"
                className="w-50"
                type="submit"
                onClick={submitHandler}
              >
                Zapłać
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrderSummaryPane;
