import React from "react";
import {useSearchParams} from "react-router-dom";

import "./payment-error-page.css"

function PaymentErrorPage() {
    const [searchParams] = useSearchParams();
    const bookingId = searchParams.entries().next().value;
    console.log(bookingId)
    return (
        <div className="payment-error" >
            <div className="payment-error-container">
                <span className="payment-error-span">Numer rezerwacji: <b>{bookingId}</b></span>
                <h1>Wystąpił błąd podczas płatności</h1>
                <span>Skontaktuj się z dostawcą płatności</span>
            </div>
        </div>
    );
}

export default PaymentErrorPage;