import React, { useState, useContext, useRef } from "react";
import SopotakContext from "../../../store/sopotak-context";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import { Col, Container, FloatingLabel, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import pl from "react-phone-number-input/locale/pl.json";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import BoatTermsModal from "./BoatTermsModal";
import "./contactPane.css"; // Import your custom CSS file
import "../../../styles.css"

const ContactPane = (props) => {
  const modalBodyRef = useRef();
  const [invoice, setInvoice] = useState(false);
  const [invoiceCountry, setInvoiceCountry] = useState("PL");
  const ctx = useContext(SopotakContext);

  const [validated, setValidated] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [approvalChecked, setApprovalChecked] = useState(false);
  const [boatTermsConfirmed, setBoatTermsConfirmed] = useState(false);
  const [termsButtonDisabled, setTermsButtonDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("PL");

  const [show, setShow] = useState(false);

  const CountrySelect = ({ value, onChange, labels, ...rest }) => (
    <Form.Select
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
      className="custom-input" // Apply the custom class
    >
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {labels[country]} +{getCountryCallingCode(country)}
        </option>
      ))}
    </Form.Select>
  );

  const handleClose = () => {
    setBoatTermsConfirmed(false);
    setShow(false);
  };
  const handleConfirm = () => {
    setBoatTermsConfirmed(true);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.progressHandler(3);
    }

    setValidated(true);
  };

  const handleInvoiceCheckboxChange = (event) => {
    ctx.setInvoice(!invoice);
    setInvoice(!invoice);
  };

  const handleInvoiceCountryChange = (event) => {
    setInvoiceCountry(event.target.value);
  };

  const handleTermsChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  const handleApproveChange = (event) => {
    setApprovalChecked(event.target.checked);
  };

  const handleMarketingChange = (event) => {
    ctx.setMarketingInfoApproved(event.target.checked);
  };

  const handleBoatTermsConfirmedChange = (event) => {
    if (event.target.checked) {
      handleShow();
    }
    setBoatTermsConfirmed(event.target.checked);
  };

  const recommendationChangeHandler = (event) => {
    ctx.setRecommendation(event.target.value);
  };

  const handleScroll = () => {
    if (
      Math.abs(
        modalBodyRef.current.scrollHeight -
          modalBodyRef.current.clientHeight -
          modalBodyRef.current.scrollTop
      ) <= 1
    ) {
      console.log("You are in the bottom");
      setTermsButtonDisabled(false);
    } else {
      setTermsButtonDisabled(true);
    }
  };

  function isValidNip(nip) {
    if (typeof nip !== "string") return false;

    nip = nip.replace(/[\ \-]/gi, "");

    let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    let sum = 0;
    let controlNumber = parseInt(nip.substring(9, 10));
    let weightCount = weight.length;
    for (let i = 0; i < weightCount; i++) {
      sum += parseInt(nip.substr(i, 1)) * weight[i];
    }

    return sum % 11 === controlNumber;
  }

  function isEmailValid(email) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!reg.test(email)) return false;
    else return true;
  }

  function isPhoneNumberValid() {
    let number = "+" + getCountryCallingCode(country) + phoneNumber;
    console.log("country number", getCountryCallingCode(country));
    console.log("phone number", number);
    return isValidPhoneNumber(number);
  }

  function getPostalCodeErrorMessage() {
    return ctx.postalCode.length === 0
      ? "Wypełnij pole."
      : ctx.postalCode.length < 5
      ? "Kod pocztowy jest zbyt krótki."
      : ctx.postalCode.length > 6
      ? "Kod pocztowy jest zbyt długi."
      : "";
  }

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    ctx.setPhoneNumber("+" + getCountryCallingCode(country) + value);
  };

  function navigateToOrderPage() {
    ctx.setOffersData(null);
    props.progressHandler(1)
  }

  return (
    <div className="form-wrapper">
      <BoatTermsModal
        show={show}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />

      <Form noValidate onSubmit={handleSubmit}>
        <Container fluid>
          <Row>
            <Col className="col-12 gx-2">
              <Form.Group>
                <FloatingLabel
                  controlId="name"
                  label="Imię *"
                  style={{ marginBottom: "10px" }}
                >
                  <Form.Control
                    type="text"
                    id="name"
                    placeholder="Grzegorz"
                    value={ctx.firstName}
                    onChange={(e) => ctx.setFirstName(e.target.value)}
                    required
                    isInvalid={validated && ctx.firstName.length < 1}
                  />
                  <Form.Control.Feedback type="invalid">
                    Wypełnij pole.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="col-12 gx-2">
              <Form.Group>
                <FloatingLabel controlId="name" label="Nazwisko *">
                  <Form.Control
                    type="text"
                    id="surname"
                    placeholder="Brzęczyszczykiewicz"
                    value={ctx.lastName}
                    onChange={(e) => ctx.setLastName(e.target.value)}
                    required
                    isInvalid={validated && ctx.lastName.length < 1}
                  />
                  <Form.Control.Feedback type="invalid">
                    Wypełnij pole.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="col-12 gx-2">
              <Form.Group>
                <FloatingLabel controlId="name" label="Email *">
                  <Form.Control
                    type="text"
                    id="email"
                    placeholder="grzegorz.brzęczyszczykiewicz@gmail.com"
                    value={ctx.email}
                    onChange={(e) => ctx.setEmail(e.target.value)}
                    required
                    isInvalid={validated && !isEmailValid(ctx.email)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Wprowadź prawidłowy adres e-mail.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="col-6 gx-2">
              <FloatingLabel label="Kod kraju *">
                <CountrySelect
                  labels={pl}
                  value={country}
                  onChange={setCountry}
                  name="countrySelect"
                />
              </FloatingLabel>
            </Col>
            <Col className="col-6 gx-2">
              <Form.Group>
                <FloatingLabel controlId="name" label="Telefon komórkowy *">
                  <Form.Control
                    type="tel"
                    id="tel"
                    placeholder="+48 500 600 700"
                    value={phoneNumber}
                    onChange={(e) => handlePhoneNumberChange(e.target.value)}
                    required
                    isInvalid={validated && !isPhoneNumberValid()}
                    className="custom-input" // Apply the custom class
                  />
                  <Form.Control.Feedback type="invalid">
                    Wprowadź prawidłowy nr telefonu.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 gx-2 mb-2">
              <Form.Check
                checked={invoice}
                onChange={handleInvoiceCheckboxChange}
                label={
                  <>
                    Chcę fakturę (<strong>ważne!</strong> - faktury VAT z NIP-em wystawiamy tylko przed zakupem usługi)
                  </>
                }
              />
            </Col>
          </Row>
          {invoice && (
            <>
              <Row>
                <Col className="col-12 gx-2">
                  <Form.Check
                    inline
                    type="radio"
                    id="invoiceCountryPL"
                    name="invoiceCountry"
                    value="PL"
                    checked={invoiceCountry === "PL"}
                    onChange={handleInvoiceCountryChange}
                    label="Polski NIP"
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="invoiceCountryOther"
                    name="invoiceCountry"
                    value="Other"
                    checked={invoiceCountry === "Other"}
                    onChange={handleInvoiceCountryChange}
                    label="Zagraniczny NIP"
                  />
                </Col>
              </Row>

              <Row>
                <Col className="col-12 gx-2">
                  <Form.Group>
                    <FloatingLabel
                      controlId="name"
                      label="Nazwa firmy *"
                      style={{ marginBottom: "10px" }}
                    >
                      <Form.Control
                        type="text"
                        id="companyName"
                        placeholder="Easy Peasy"
                        value={ctx.companyName}
                        onChange={(e) => ctx.setCompanyName(e.target.value)}
                        required={invoice}
                        isInvalid={validated && ctx.companyName.length < 1}
                      />
                      <Form.Control.Feedback type="invalid">
                        Wypełnij pole.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              {invoiceCountry === "PL" ? (
                <Row>
                  <Col className="gx-2">
                    <Form.Group>
                      <FloatingLabel
                        controlId="name"
                        label="NIP"
                        style={{ marginBottom: "10px" }}
                      >
                        <Form.Control
                          type="text"
                          id="nip"
                          placeholder="947-068-48-57"
                          value={ctx.nip}
                          onChange={(e) => ctx.setNip(e.target.value)}
                          isInvalid={ctx.nip.length > 1 && !isValidNip(ctx.nip)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Wprowadź prawidłowy numer NIP.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col className="col-12 gx-2">
                    <Form.Group>
                      <FloatingLabel
                        controlId="name"
                        label="NIP"
                        style={{ marginBottom: "10px" }}
                      >
                        <Form.Control
                          type="text"
                          id="nip"
                          placeholder="Enter NIP"
                          value={ctx.nip}
                          onChange={(e) => ctx.setNip(e.target.value)}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row>
                <Col className="col-5 gx-2">
                  <Form.Group>
                    <FloatingLabel
                      controlId="name"
                      label="Kod pocztowy *"
                      style={{ marginBottom: "10px" }}
                    >
                      <Form.Control
                        type="text"
                        id="postalCode"
                        placeholder="02-211"
                        value={ctx.postalCode}
                        onChange={(e) => ctx.setPostalCode(e.target.value)}
                        required={invoice}
                        isInvalid={
                          validated &&
                          !(
                            ctx.postalCode.length === 5 ||
                            ctx.postalCode.length === 6
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {getPostalCodeErrorMessage()}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col className="col-7 gx-2">
                  <Form.Group>
                    <FloatingLabel
                      controlId="city"
                      label="Miasto *"
                      style={{ marginBottom: "10px" }}
                    >
                      <Form.Control
                        type="text"
                        id="city"
                        placeholder="Warszawa"
                        value={ctx.city}
                        onChange={(e) => ctx.setCity(e.target.value)}
                        required={invoice}
                        isInvalid={validated && ctx.city.length < 1}
                      />
                      <Form.Control.Feedback type="invalid">
                        Wypełnij pole.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="col-8 gx-2">
                  <Form.Group>
                    <FloatingLabel
                      controlId="street"
                      label="Ulica *"
                      style={{ marginBottom: "10px" }}
                    >
                      <Form.Control
                        type="text"
                        id="street"
                        placeholder="Nowogrodzka"
                        value={ctx.streetAddress}
                        onChange={(e) => ctx.setStreetAddress(e.target.value)}
                        required={invoice}
                        isInvalid={validated && ctx.streetAddress.length < 1}
                      />
                      <Form.Control.Feedback type="invalid">
                        Wypełnij pole.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col className="col-4 gx-2">
                  <Form.Group>
                    <FloatingLabel
                      controlId="name"
                      label="Nr domu *"
                      style={{ marginBottom: "10px" }}
                    >
                      <Form.Control
                        type="text"
                        id="apartmentNumber"
                        placeholder="22"
                        value={ctx.apartmentNumber}
                        onChange={(e) => ctx.setApartmentNumber(e.target.value)}
                        required={invoice}
                        isInvalid={validated && ctx.apartmentNumber.length < 1}
                      />
                      <Form.Control.Feedback type="invalid">
                        Wypełnij pole.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col className="col-12 gx-2">
              <Form.Group>
                <FloatingLabel
                  controlId="name"
                  label="Uwagi do zamówienia"
                  style={{ marginBottom: "10px" }}
                >
                  <Form.Control
                    as="textarea"
                    id="remarks"
                    placeholder="Uwagi"
                    value={ctx.comments}
                    onChange={(e) => ctx.setComments(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 gx-2">
              <Form.Group>
                <FloatingLabel
                  controlId="recommendations"
                  label="Skąd dowiedziałeś się o nas?"
                  style={{ marginBottom: "10px" }}
                >
                  <Form.Select
                    onChange={recommendationChangeHandler}
                    value={ctx.recommendation}
                  >
                    <option value="" />
                    <option value="Znajomy">Znajomy</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Recepcja w hotelu">Recepcja w hotelu</option>
                    <option value="Inne">Inne</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 gx-2">
              <Form.Check
                id="approveterms"
                defaultChecked={false}
                required
                label={
                  <>
                    Zgoda na{" "}
                    <a
                      href="https://cruisemybay.pl/regulamin-serwisu/"
                      target="_blank"
                    >
                      regulamin serwisu
                    </a>
                  </>
                }
                feedback="Musisz się zgodzić zanim przejdziesz dalej."
                feedbackType="invalid"
                onChange={(e) => {
                  handleTermsChange(e);
                }}
                isInvalid={validated && termsChecked === false}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 gx-2">
              <Form.Check
                id="approveelectronic"
                defaultChecked={false}
                required
                label="Zgoda na świadczenie usług drogą elektroniczną"
                feedback="Musisz się zgodzić zanim przejdziesz dalej."
                feedbackType="invalid"
                onChange={(e) => {
                  handleApproveChange(e);
                }}
                isInvalid={validated && approvalChecked === false}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 gx-2">
              <Form.Check
                id="approveboatterms"
                defaultChecked={false}
                required
                checked={boatTermsConfirmed}
                label={
                  <>
                  Potwierdzenie zapoznania się z regulaminem jachtu (<strong>ważne!</strong>)
                  </>
                }
                feedback="Musisz przeczytać regulamin łodzi oraz potwierdzić zanim przejdziesz dalej."
                feedbackType="invalid"
                onChange={(e) => {
                  handleBoatTermsConfirmedChange(e);
                }}
                isInvalid={validated && boatTermsConfirmed === false}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} className="col-12 gx-2">
              <Form.Check
                id="marketing"
                defaultChecked={false}
                value={ctx.marketingInfoApproved}
                label="Zgoda na otrzymywanie na adres e-mail newslettera oraz materiałów reklamowo-marketingowych"
                onChange={(e) => {
                  handleMarketingChange(e);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-6 gx-2">
              <Button
                size="lg"
                onClick={navigateToOrderPage}
                className="w-100"
              >
                Wstecz
              </Button>
            </Col>
            <Col className="col-6 gx-2">
              <Button size="lg" type="submit" className="w-100">
                Dalej
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default ContactPane;
