import React from "react";
import {createRoot} from "react-dom/client";
import "./styles.css";
import App from "./App";
import {SopotakContextProvider} from "./store/sopotak-context";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <SopotakContextProvider>
        <App/>
    </SopotakContextProvider>
);
