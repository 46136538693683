import React, { useContext, useState } from "react";
import OrderPane from "../form/order/OrderPane";
import SearchPane from "../form/search/SearchPane";
import SopotakContext from "../../store/sopotak-context";
import ContactPane from "../form/contact/ContactPane";
import OrderSummaryPane from "../form/summary/OrderSummaryPane";

import ProgressSteps from "../progressbar/ProgressSteps";
import SearchWidget from "../form/search/SearchWidget";

function OrderPage() {
  const ctx = useContext(SopotakContext);
  const [step, setStep] = useState(1);

  console.log("Order Page");
  console.log(ctx);
  console.log(step);

  function handleProgress(stepNumber) {
    setStep(stepNumber);
  }

  return (
    <div style={{padding: "1rem"}}>
      {step === 1}
      {/*<ProgressSteps step={step} />*/}

      {step === 1 && <SearchWidget mainPage={false} />}

      <div className="form-pane-wrapper">
        {step === 1 && <OrderPane progressHandler={handleProgress} />}
        {step === 2 && <ContactPane progressHandler={handleProgress} />}
        {step === 3 && <OrderSummaryPane progressHandler={handleProgress} />}
      </div>
    </div>
  );
}

export default OrderPage;
