import React, { useEffect, useState, forwardRef } from "react";
import OrderItem from "./OrderItem";
import styles from "./orderListPanel.module.css";
import { Col, Container, Row } from "react-bootstrap";

const OrderListPanel = forwardRef((props, ref) => {
   const orderList = props.orderList || [];
  // Use a single state to store the index of the checked item
  const [checkedIndex, setCheckedIndex] = useState(null);

  useEffect(() => {
    setCheckedIndex(null); // Reset on orderList change
  }, [orderList]);

  const checkStateHandler = (index) => {
    if (checkedIndex === index) {
      setCheckedIndex(null); // Uncheck if the same index is clicked again
      props.offerHandler(null);
    } else {
      setCheckedIndex(index); // Set the new checked index
      props.offerHandler(orderList[index]);
    }
  };

  const calculateIsDisabled = (order, direction) => {
    const orderDepartureTime = new Date(order.departureTime).getTime();
    const orderArrivalTime = new Date(order.arrivalTime).getTime();
    const bufferTime = 0;//8 * 60 * 1000; // 10 minutes buffer

    if (direction === "Z powrotem" && props.selectedDepartureEndTime) {
      const minimumDepartureTime = props.selectedDepartureEndTime + bufferTime;
      return orderDepartureTime < minimumDepartureTime;
    }

    if (direction === "Tam" && props.selectedReturnStartTime) {
      const maximumDepartureTime = props.selectedReturnStartTime - bufferTime;
      return orderArrivalTime > maximumDepartureTime;
    }

    return false;
  };

  return (
    <div ref={ref}>
      <Container fluid>
        <Row>
          <Col className="col-6 gx-2 mb-2">
            <h4 style={{ textAlign: "left" }}>{props.direction}</h4>
          </Col>
          <Col className="col-6 gx-2 mb-2">
            <h4 style={{ textAlign: "right", color: "grey" }}>{props.date}</h4>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 gx-2 mb-2">
            {orderList.length > 0 ? (
              <ul className={styles.list}>
                {orderList.map((order, index) => {
                  const isDisabled = calculateIsDisabled(
                    order,
                    props.direction
                  );

                  return (
                    <li key={index}>
                      <OrderItem
                        departureTime={order.departureTime}
                        arrivalTime={order.arrivalTime}
                        index={index}
                        price={order.price.amount}
                        currency={order.price.currency}
                        checked={checkedIndex === index}
                        checkHandler={checkStateHandler}
                        disabled={isDisabled} // Pass the disabled state
                      />
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p>
                Nie znaleziono żadnych możliwych kursów w kalendarzu dostępności
                sternika. Spróbuj zmienić daty podróży.
              </p>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default OrderListPanel;
