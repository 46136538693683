import React, { useState, useContext, useEffect } from "react";
import SopotakContext from "../../../store/sopotak-context";
import SearchForm from "./SearchForm";
import classes from "./SearchPane.module.css";

const SearchPane = (props) => {
  const ctx = useContext(SopotakContext);

  return (
    <div
      className={props.mainPage ? classes.searchPaneMain : classes.searchPane}
    >
      {/* <div className={classes.tripTypeButtons}>
        <button
          className={
            ctx.tripType === "RETURN"
              ? classes.buttonActive
              : classes.buttonInactive
          }
          onClick={() => ctx.setTripType("RETURN")}
        >
          Tam i z powrotem
        </button>
        <button 
        className={
          ctx.tripType === "ONEWAY"
            ? classes.buttonActive
            : classes.buttonInactive
        }
        onClick={() => ctx.setTripType("ONEWAY")}>
          W jedną stronę
        </button>
        <button 
        className={
          ctx.tripType === "TIME_BASED"
            ? classes.buttonActive
            : classes.buttonInactive
        }
        onClick={() => ctx.setTripType("TIME_BASED")}>
          Kurs czasowy
        </button>
      </div> */}
      <SearchForm mainPage={props.mainPage} />
    </div>
  );
};

export default SearchPane;
