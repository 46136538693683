import React, { useCallback, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '500px',
};

const MapComponent = ({ allLocations, selectedLocations }) => {
  const mapRef = React.useRef(null);

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    allLocations.forEach((location) => {
      if (location.lat && location.lng) {
        bounds.extend(location);
      }
    });
    map.fitBounds(bounds);
    mapRef.current = map;
  }, [allLocations]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;

      if (selectedLocations.length > 1) {
        const bounds = new window.google.maps.LatLngBounds();
        selectedLocations.forEach(location => {
          bounds.extend(location);
        });
        map.fitBounds(bounds);
      } else if (selectedLocations.length === 1) {
        map.setCenter(selectedLocations[0]);
        map.setZoom(13); // zoom when one location selected
      } else {
        // No locations selected, revert to bounds of all locations
        const bounds = new window.google.maps.LatLngBounds();
        allLocations.forEach(location => {
          bounds.extend(location);
        });
        map.fitBounds(bounds);
      }
    }
  }, [selectedLocations, allLocations]);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
      >
        {selectedLocations.map((location, index) => (
          <Marker key={index} position={location} />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
