import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import "react-step-progress-bar/styles.css";
import SopotakContext from "../../../store/sopotak-context";
import OrderListPanel from "./OrderListPanel";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "../../../styles.css"

const OrderPane = (props) => {
  const {
    tripType,
    departureOffer,
    returnOffer,
    setDepartureOffer,
    setReturnOffer,
    offersData,
  } = useContext(SopotakContext);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedDepartureEndTime, setSelectedDepartureEndTime] = useState(null);
  const [selectedReturnStartTime, setSelectedReturnStartTime] = useState(null);

  const departureListRef = useRef(null);

  useEffect(() => {
    console.log("offers state", departureOffer, returnOffer);
    if (tripType === "RETURN") {
      setIsButtonDisabled(!departureOffer || !returnOffer);
    } else {
      setIsButtonDisabled(!departureOffer);
    }
  }, [departureOffer, returnOffer, tripType]);

  useEffect(() => {
    setSelectedDepartureEndTime(null);
    setSelectedReturnStartTime(null);
    setDepartureOffer(null);
    setReturnOffer(null);
    if (offersData?.departureOffers?.length && departureListRef.current) {
      departureListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [offersData]);

  const submitHandler = (event) => {
    event.preventDefault();
    props.progressHandler(2);
    console.log("context after selection", { tripType, departureOffer, returnOffer });
  };

  const handleDepartureOffer = useCallback((offer) => {
    setDepartureOffer(offer);
    if (offer) {
      setSelectedDepartureEndTime(new Date(offer.arrivalTime).getTime());
    } else {
      setSelectedDepartureEndTime(null);
    }
  }, [setDepartureOffer]);

  const handleReturnOffer = useCallback((offer) => {
    setReturnOffer(offer);
    if (offer) {
      setSelectedReturnStartTime(new Date(offer.departureTime).getTime());
    } else {
      setSelectedReturnStartTime(null);
    }
  }, [setReturnOffer]);

  const renderOffers = () => {
    console.log("times", new Date(selectedDepartureEndTime), " ", new Date(selectedReturnStartTime));
    const departureOffersExist =
      offersData &&
      offersData.departureOffers &&
      offersData.departureOffers.length > 0;
    if (!offersData) {
      return null;
    } else if (offersData && !departureOffersExist) {
      return (
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs="auto">
              <h5>
                Nie znaleziono ofert. Zmień parametry wyszukiwania lub spróbuj
                później.
              </h5>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (
        <div className="form-wrapper">
          <Container fluid>
            <Row style={{ marginBottom: "30px" }}>
              <Col className="col-12 gx-2">
                <OrderListPanel
                  ref={departureListRef}
                  orderList={offersData.departureOffers}
                  direction="Tam"
                  date={offersData.departureDate}
                  offerHandler={handleDepartureOffer}
                  selectedReturnStartTime={selectedReturnStartTime}
                />
              </Col>
            </Row>
            {tripType === "RETURN" && (
              <Row className="mb-2">
                <Col className="col-12 gx-2">
                  <OrderListPanel
                    orderList={offersData.returnOffers}
                    direction="Z powrotem"
                    date={offersData.arrivalDate}
                    offerHandler={handleReturnOffer}
                    selectedDepartureEndTime={selectedDepartureEndTime}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col className="col-12 gx-2">
                <Button
                  size="lg"
                  onClick={submitHandler}
                  className="w-100"
                  disabled={isButtonDisabled}
                >
                  Rezerwuj
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  };

  return (
    <div>
      <section>{renderOffers()}</section>
    </div>
  );
};

export default OrderPane;
