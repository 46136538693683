import React from "react";
import { useSearchParams } from "react-router-dom";
import config from "../../api/api-helper";

import "./payment-error-page.css";
function BookingCancelPage() {
  const [searchParams] = useSearchParams();
  const bookingId = searchParams.entries().next().value;
  const [isCancelling, setIsCancelling] = React.useState(false);
  const [cancelSuccess, setCancelSuccess] = React.useState(false);
  const [cancelError, setCancelError] = React.useState(null);

  const cancelBookingHandler = () => {
    setIsCancelling(true);
    setCancelError(null);
    console.log("booking id", bookingId[0]);

    fetch(
      `${config.apiUrl}/api/sopotak/v1/bookings/${bookingId[0]}/cancel`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        setIsCancelling(false);
        const data = await response.json();
    
        if (response.ok) {
          setCancelSuccess(true);
        } else if (response.status === 403 && data.message === "BOOKING_CANCELLATION_PERIOD_EXCEEDED") {
          throw new Error("Anulowanie jest możliwe nie później niż 5 dni przed kursem.");
        } else if (response.status === 403 && data.message === "BOOKING_ALREADY_CANCELLED") {
          throw new Error("Zamówienie zostało już anulowane");
        } else if (response.status === 404) {
          throw new Error("Nie znaleziono rezerwacji o podanym numerze ID.");
        } else {
          throw new Error(data.message || "Wystąpił nieoczekiwany błąd.");
        }
      })
      .catch((error) => {
        setIsCancelling(false);
        setCancelError(error.message);
      });
  };

  return (
    <div className="payment-error">
      <div className="payment-error-container">
        <div className="payment-error-spacer" />
        <span className="payment-error-span">
          Numer rezerwacji: <b>{bookingId}</b>
        </span>
        {cancelSuccess ? (
          <div className="cancel-success-message">
            Rezerwacja została anulowana.
          </div>
        ) : (
          <>
            <button
              className="cancel-booking-button"
              onClick={cancelBookingHandler}
              disabled={isCancelling}
            >
              {isCancelling ? "Anulowanie..." : "Anuluj rezerwację"}
            </button>
            {cancelError && (
              <div className="cancel-error-message">
                Błąd anulowania: {cancelError}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default BookingCancelPage;
