import React from 'react';

import "./navbar.css"


const Navbar = () => {
    return (
        <nav>
            <div className="navigation">
                <a href="https://cruisemybay.pl/">
                    <img className="logo" src={`${process.env.PUBLIC_URL}/cruise-my-bay-white-font-on-transparent-background.png`}/>
                </a>
            </div>
        </nav>
    )
}

export default Navbar