import React from "react";

import "./ok-page.css"
import {useSearchParams} from "react-router-dom";

function OkPage() {
    const [searchParams] = useSearchParams();
    const bookingId = searchParams.entries().next().value;
    console.log(bookingId)
    return (
        <div className="confirmation">
            <div className="confirmation-container">
                <span className="confirmation-booking-span">Numer rezerwacji: <b>{bookingId}</b></span>
                <h1>Płatność jest przetwarzana</h1>
                <span>Oczekuj wiadomości e-mail z potwierdzeniem</span>
            </div>
        </div>
    );
}

export default OkPage;