import React from "react";

function SummaryPage() {
    return(
        <div>
            <h2>Twoje zamówienie jest aktualnie przetwarzane. Otrzymasz potwierdzenie w wiadomości e-mail.</h2>
        </div>
    );
}

export default SummaryPage;