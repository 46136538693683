import React from 'react';

import "./footer.css"


const Footer = () => {
    const year = new Date().getFullYear();

    return <footer>
        <div className="footer-container">
            <div>
                <i className="footer-icon fas fa-envelope"></i> <a
                href="mailto:info@cruisemybay.pl">info@cruisemybay.pl</a><br/>
                <i className="footer-icon fas fa-phone"></i> <a
                href="tel:+48 535 939 273">+48 535 939 273</a>
            </div>
            <div className="footer-spacer"/>
            <div>
                <a href="https://www.facebook.com/profile.php?id=61560368245508" className="footer-social-icon fab fa-facebook"></a>
                <a href="https://www.instagram.com/cruisemybay.pl/" className="footer-social-icon fab fa-instagram"></a>
            </div>
            <div className="footer-spacer"/>
            <hr/>
            <span className="footer-copyright">Copyright © CruiseMyBay {year}</span>
        </div>
    </footer>;
};

export default Footer;