import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../../styles.css"

const BoatTermsModal = ({ show, handleClose, handleConfirm }) => {
  const modalBodyRef = useRef();
  const [termsButtonDisabled, setTermsButtonDisabled] = useState(true);

  const handleScroll = () => {
    if (
      Math.abs(
        modalBodyRef.current.scrollHeight -
          modalBodyRef.current.clientHeight -
          modalBodyRef.current.scrollTop
      ) <= 1
    ) {
      console.log("You are in the bottom");
      setTermsButtonDisabled(false);
    } else {
      setTermsButtonDisabled(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Regulamin jachtu</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={modalBodyRef} onScroll={handleScroll}>
        <div>
          <p>
            Niniejszy regulamin, zwany dalej „<strong>Regulaminem</strong>”
            określa warunki użytkowania jachtu SAXDOR 320 GTC zwanego dalej „
            <strong>Jachtem</strong>” przez osoby korzystające z czarteru
            Jachtu, zwane dalej „<strong>Użytkownikami</strong>”.
          </p>

          <ol>
            <li>
              Każdy Użytkownik Jachtu zobowiązany jest stosować się do
              Regulaminu oraz zaleceń, instrukcji i poleceń sternika Jachtu oraz
              załogi.
            </li>

            <li>
              Jacht może być użytkowany na Zatoce Gdańskiej i pełnym morzu
              przy maksymalnej prędkości wiatru do 26 węzłów w podstawie (tj.
              6 stopni w skali Beauforta) w momencie wypłynięcia Jachtu z
              przystani początkowej. W przypadku większej siły wiatru lub
              prognoz przewidujących pogorszenie się warunków atmosferycznych
              sternik ma prawo odwołać rejs lub zakończyć go.
            </li>

            <li>
              Jacht może być użytkowany wyłącznie przy udziale sternika Jachtu,
              którego obsługę i udział w rejsie zapewnia właściciel Jachtu.
            </li>

            <li>
              Użytkownik oraz sternik oświadczają, iż zdają sobie sprawę, że
              korzystanie z Jachtu wiąże się z ryzykiem zagrożenia wypadkami,
              kontuzjami lub innymi urazami fizycznymi, jak również ryzykiem
              wystąpienia szkód majątkowych. Korzystanie z Jachtu na wodzie w
              skrajnym przypadku może wiązać się z ryzykiem wywrotki, co stwarza
              bezpośrednie zagrożenie utonięcia lub wychłodzenia organizmu oraz
              uszkodzeń ciała.
            </li>

            <li>
              Na Jachcie wymagane jest odpowiednie obuwie z miękką podeszwą.
              Osoby w nieodpowiednim obuwiu mogą zostać poproszone o zdjęcie
              butów i pozostanie na boso. Właściciel Jachtu zaleca posiadanie
              odzieży na przebranie oraz trzymanie rzeczy osobistych w
              wodoszczelnych opakowaniach.
            </li>

            <li>
              Właściciel Jachtu ani sternik nie ponoszą odpowiedzialności za
              sprzęt elektroniczny, rzeczy wartościowe oraz inne przedmioty
              Użytkowników pozostawione na przystani jachtowej oraz zabierane ze
              sobą na wodę.
            </li>

            <li>
              Właściciel Jachtu zapewnia Użytkownikom oraz sternikowi kamizelki
              ratunkowe. Właściciel zaleca używanie kamizelek ratunkowych przez
              wszystkich Użytkowników oraz sternika. Użytkownicy Jachtu bez
              kamizelek ratunkowych robią to wyłącznie na własne ryzyko.
              Obowiązek korzystania z kamizelki ratunkowej ma jednak każda osoba
              nie umiejąca pływać oraz osoby niepełnoletnie, a w szczególności
              dzieci. Właściciel Jachtu ani sternik nie są zobowiązani do
              sprawdzenia umiejętności pływania Użytkowników. Właściciel Jachtu
              nie zapewnia ubezpieczenia Użytkownikom w czasie czarteru Jachtu
              oraz zaleca wpisanie do telefonu komórkowego numeru ratunkowego
              WOPR i Policji (WOPR – 984, Numer alarmowy – 112).
            </li>

            <li>
              Użytkownikami Jachtu nie mogą być osoby niepełnoletnie bez opieki
              osoby dorosłej. Za osoby niepełnoletnie korzystające z Jachtu
              odpowiadają rodzice lub opiekunowie.
            </li>

            <li>
              Na Jachcie podczas rejsu może przebywać maksymalnie 9 osób (razem
              z członkami załogi). W przypadku podjęcia przez Użytkowników próby
              wejścia na pokład Jachtu w większej liczbie osób niż deklarowana
              podczas dokonywania rezerwacji w systemie
              rezerwacje.cruisemybay.pl, sternik jest uprawniony do odmowy
              wpuszczenia na pokład Jachtu bez możliwości zwrotu poniesionych
              przez Użytkownika kosztów oraz przeniesienia dokonanej wpłaty ceny
              za rezerwację Jachtu na inny termin. Dotyczy to w szczególności
              sytuacji, w której liczba osób przekraczałaby maksymalną liczbę
              określoną powyżej.
            </li>

            <li>
              Jacht jest przystosowany do przewozu maksymalnie i łącznie 2
              rowerów i/lub sztuk sprzętu lub bagaży wielkogabarytowych (tj.
              suma długości+szerokości+wysokości {">"} 200 cm) i zawsze pod
              opieką przynajmniej 1 osoby dorosłej. Za ich umieszczenie i
              rozlokowanie na pokładzie Jachtu odpowiada sternik. W przypadku
              podjęcia przez Użytkownika próby wejścia na pokład Jachtu z
              większą niż deklarowana podczas dokonywania rezerwacji w systemie
              rezerwacje.cruisemybay.pl liczbą rowerów, sztuk sprzętu lub
              bagaży, sternik jest uprawniony do odmowy wpuszczenia na pokład
              Jachtu bez możliwości zwrotu poniesionych przez Użytkownika
              kosztów oraz przeniesienia dokonanej wpłaty ceny za rezerwację
              Jachtu na inny termin.
            </li>

            <li>
              Jacht wyposażony jest w toaletę. Użytkownicy zobowiązani są do
              korzystania z niej zgodnie z instrukcją. W szczególności
              zabronione jest wrzucanie do niej jakichkolwiek przedmiotów,
              papieru toaletowego oraz środków higieny osobistej. Należy je
              umieszczać w pojemniku znajdującym się w pomieszczeniu toalety,
              przeznaczonym do tego celu.
            </li>

            <li>
              Podczas rejsu Jachtem obowiązuje bezwzględny zakaz spożywania
              alkoholu i środków odurzających. Zakaz dotyczy zarówno sternika
              Jachtu jak Użytkowników. W przypadku stwierdzenia przez sternika
              podejrzenia przebywania Użytkownika pod wpływem alkoholu lub
              środków odurzających, który to stan może zagrażać bezpieczeństwu
              pozostałych Użytkowników, bezpieczeństwu sternika lub Jachtu lub
              też bezpiecznemu i spokojnemu przebiegowi rejsu, sternik jest
              uprawniony do jego przerwania oraz podjęcia decyzji o powrocie do
              przystani (bez możliwości zwrotu poniesionych przez Użytkowników
              kosztów oraz przeniesienia dokonanej wpłaty ceny za rezerwację
              Jachtu na inny termin).
            </li>

            <li>
              Na Jachcie obowiązuje bezwzględny zakaz palenia wyrobów
              tytoniowych oraz zakaz używania otwartego ognia.
            </li>

            <li>
              Użytkownicy zobowiązują się używać powierzonego im na Jachcie
              sprzętu zgodnie z przeznaczeniem oraz zwrócić sternikowi sprzęt w
              stanie niepogorszonym.
            </li>

            <li>
              Użytkownicy ponoszą odpowiedzialność za zniszczenie lub zagubienie
              powierzonego im wyposażenia Jachtu (np. kamizelki ratunkowej), w
              przypadku gdy do zniszczenia doszło na skutek okoliczności
              zawinionych przez Użytkownika.
            </li>

            <li>
              Na wyposażenie Jachtu składają się m. in.:
              <ul>
                <li>kamizelki ratunkowe: 9 sztuk,</li>

                <li>koło ratunkowe: 1 sztuka</li>

                <li>linka ratunkowa: 25 m</li>

                <li>apteczka: 1 sztuka</li>

                <li>gaśnica: 2 sztuki</li>

                <li>koc gaśniczy: 1 sztuka</li>
              </ul>
            </li>
            <li>
              Użytkownicy zobowiązują się utrzymywać na pokładzie Jachtu
              czystość oraz nie zaśmiecać go.
            </li>

            <li>
              Użytkownicy zobowiązują się nie wyrzucać żadnych przedmiotów i
              rzeczy za burtę Jachtu.
            </li>

            <li>
              Użytkowników obowiązuje bezwzględny zakaz opuszczania pokładu
              Jachtu podczas rejsu w celu kąpieli w morzu bez zgody członków
              załogi.
            </li>

            <li>
              Użytkowników obowiązuje zakaz przewozu zwierząt Jachtem. Sternik
              jest uprawniony do odmowy wejścia na pokład Jachtu Użytkownika
              wraz ze zwierzęciem. W takim przypadku, Użytkownikowi nie
              przysługuje zwrot poniesionych przez niego kosztów oraz
              uprawnienie do przeniesienia dokonanej wpłaty ceny za rezerwację
              Jachtu na inny termin.
            </li>

            <li>
              Jacht jest ubezpieczony w zakresie OC, NNW i JACHT-CASCO. W
              przypadku awarii lub innej szkody uniemożliwiającej kontynuowanie
              rejsu lub zagrażającej bezpieczeństwu Użytkowników, sternika lub
              Jachtu, sternik zobowiązany jest do niezwłocznego telefonicznego
              powiadomienia właściciela Jachtu, natychmiastowego zakończenia
              rejsu oraz powrotu do przystani.
            </li>

            <li>
              Właściciel oświadcza, że udostępnia Użytkownikom w pełni sprawny
              Jacht. W przypadku jednak zdarzenia siły wyższej oraz zaistnienia
              awarii w okresie trwania rejsu, powodującej wyłączenie Jachtu z
              eksploatacji, niesprzyjających warunków atmosferycznych,
              utrudniających lub uniemożliwiających rejs, ewentualnie innych
              zdarzeń powodujących przerwy w rejsie, za które właściciel Jachtu
              i sternik nie ponoszą odpowiedzialności, Właściciel w miarę
              możliwości zaproponuje Użytkownikom inny termin czarteru Jachtu.
            </li>

            <li>
              Wejście na Jacht przez Użytkownika jest jednoznaczne z akceptacją
              niniejszego Regulaminu.
            </li>

            <li>
              Użytkownik dokonujący rezerwacji Jachtu za pośrednictwem serwisu
              rezerwacje.cruisemybay.pl akceptuje niniejszy Regulamin oraz
              otrzymuje jego treść na podany przez siebie podczas dokonywania
              rezerwacji adres e-mail.
            </li>

            <li>
              Niniejszy Regulamin Jachtu jest do wglądu dla każdego Użytkownika
              na pokładzie Jachtu.
            </li>

            <li>
              Użytkownik oświadcza, że zapoznał się z Regulaminem i akceptuje
              jego postanowienia.
            </li>
          </ol>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>
          Zamknij
        </Button>
        <Button
          variant="success"
          onClick={handleConfirm}
          disabled={termsButtonDisabled}
        >
          Zrozumiałem
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BoatTermsModal;
