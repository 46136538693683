import React, { useState } from 'react'
import styled from 'styled-components'

const MainContainer = styled.div`
  width: 50%;
  max-width: 600px;
  margin: 0 auto 3rem;
  padding: 0 16px;
`

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: grey;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: black;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`

const StepStyle = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid ${({ step }) =>
      step === 'completed' ? 'black' : 'grey'};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StepCount = styled.span`
  font-size: 1rem;
  color: ${({ step }) =>
  step === 'completed' ? 'black' : 'grey'};
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StepLabel = styled.span`
  font-size: 1rem;
  color: ${({ step }) =>
  step === 'completed' ? 'black' : 'grey'};;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 100px;
`

const ButtonStyle = styled.button`
  border-radius: 4px;
  border: 0;
  background: #4a154b;
  color: #ffffff;
  cursor: pointer;
  padding: 8px;
  width: 90px;
  :active {
    transform: scale(0.98);
  }
  :disabled {
    background: #f3e7f3;
    color: #000000;
    cursor: not-allowed;
  }
`

const CheckMark = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`

const steps = [
  {
    label: 'Wybierz',
    step: 1,
  },
  {
    label: 'Zamów',
    step: 2,
  },
  {
    label: 'Zapłać',
    step: 3,
  },
]

const ProgressSteps = (props) => {

    const activeStep = props.step;
//   const [activeStep, setActiveStep] = useState(1);

//   const nextStep = () => {
//     setActiveStep(activeStep + 1);
//   }

//   const prevStep = () => {
//     setActiveStep(activeStep - 1);
//   }

  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <MainContainer >
      <StepContainer width={width}>
        {steps.map(({ step, label }) => (
          <StepWrapper key={step}>
            <StepStyle step={activeStep >= step ? 'completed' : 'incomplete'}>
              {activeStep > step ? (
                <CheckMark>L</CheckMark>
              ) : (
                <StepCount>{step}</StepCount>
              )}
            </StepStyle>
            <StepsLabelContainer>
              <StepLabel key={step}>{label}</StepLabel>
            </StepsLabelContainer>
          </StepWrapper>
        ))}
      </StepContainer>
      {/* <ButtonsContainer>
        <ButtonStyle onClick={prevStep} disabled={activeStep === 1}>
          Previous
        </ButtonStyle>
        <ButtonStyle onClick={nextStep} disabled={activeStep === totalSteps}>
          Next
        </ButtonStyle>
      </ButtonsContainer> */}
    </MainContainer>
  );
}

export default ProgressSteps;