import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import OrderPage from "./components/pages/OrderPage";
import MainPage from "./components/pages/MainPage";
import SummaryPage from "./components/pages/SummaryPage";
import OkPage from "./components/pages/OkPage";
import PaymentErrorPage from "./components/pages/PaymentErrorPage";
import BookingCancelPage from "./components/pages/BookingCancelPage";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import "./styles.css"

function App() {
    return (
        <div className="main" style={{
            //backgroundImage: "url(/bg.png)",
            //backgroundRepeat: "repeat",
            height: "100%",
            position: "relative",
            width: "100%"
        }}>
            <Navbar/>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MainPage/>}></Route>
                    <Route path="/order" element={<OrderPage/>}></Route>
                    <Route path="/summary" element={<SummaryPage/>}></Route>
                    <Route path="/booking/ok" element={<OkPage/>}></Route>
                    <Route path="/booking/error" element={<PaymentErrorPage/>}></Route>
                    <Route path="/booking/cancel" element={<BookingCancelPage />} />
                </Routes>
            </BrowserRouter>
            <Footer/>
        </div>
    );
}

export default App;
//http://localhost:3000/order?type=RETURN&departureLocation=Hel&departureTime=2024-04-24T10%3A00%3A00.000Z&arrivalLocation=Jastarnia&adultPassengersCount=2&childPassengersCount=0&infantPassengersCount=0&durationInMinutes=30&returnTime=2024-04-24T12%3A30%3A00.000Z
//https://sopotak-app.web.app/order?type=RETURN&departureLocation=Hel&departureTime=2024-04-25T10%3A00%3A00.000Z&arrivalLocation=Jastarnia&adultPassengersCount=2&childPassengersCount=0&infantPassengersCount=0&durationInMinutes=30&returnTime=2024-04-25T12%3A30%3A00.000Z
