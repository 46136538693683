const env = process.env.REACT_APP_ENV;

 const config = {
    apiUrl: process.env.REACT_APP_API_URL,
  };

export default config;



export function getLocations() {

  return fetch(`${config.apiUrl}/api/sopotak/v1/locations`)
  .then((response) => {
    return response.json();});
};

export function getRoutes() {
  return fetch(`${config.apiUrl}/api/sopotak/v1/routes`)
  .then((response) => {
    return response.json();});
};
