import React from "react";
import SearchPane from "../form/search/SearchPane";
import SearchBootstrap from "../form/search/SearchWidget";
import SearchWidget from "../form/search/SearchWidget";

function MainPage() {
    return (
        <div>
            <SearchWidget mainPage={true}/>
        </div>
    );
}

export default MainPage;
